<template>
  <div>
    <div class="text-right pb-2"></div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-start py-1">
        <b-col md="12" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack">Payment</h3>
          <p class="font-weight-bold text-colorGrey">
            Your Payment display here
          </p>
        </b-col>
      </b-row>
      <b-row class="px-2 w-100">
        <b-col md="12">
          <b-tabs content-class="mt-50">
            <b-tab class="pl-1 pt-2" active>
              <template #title>
                <feather-icon icon="SearchIcon" />
                <span class="font-weight-bolder text-colorBlack"
                  >Payment Method</span
                >
              </template>
              <b-row class="d-flex align-items-center justify-content-start">
                <b-col md="12" class="py-50">
                  <h3 class="font-weight-bolder text-colorBlack m-0">
                    Payment Method
                  </h3>
                </b-col>
              </b-row>

              <b-card no-body class="mx-2 w-50" style="border-radius: 20px">
                <b-card-header>
                  <div style="align-self: end">
                    <h3 class="font-weight-bolder text-colorBlack">Swish</h3>
                  </div>
                  <div>
                    <img
                      src="@/assets/images/icons/uniHead/payment/swish.png"
                      img-fluid
                      class="cursor-pointer"
                    />
                  </div>
                </b-card-header>
                <b-card-body>
                  <p class="font-weight-bold text-colorGray">
                    Fill in the mobile number you have linked to swish. You need
                    to have Swish and Mobile BankID installed/
                  </p>
                  <b-form>
                    <b-form-row class="py-1">
                      <b-col>
                        <span class="font-weight-bolder">Account No*</span>
                        <b-form-group class="mb-0">
                          <b-form-input
                            v-bind="accountNo"
                            id="accountNo"
                            placeholder="XXXXXXXXXXXXX"
                          />
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                  </b-form>
                </b-card-body>
                <hr />
              </b-card>
            </b-tab>
            <b-tab class="pl-1">
              <template #title>
                <feather-icon icon="SearchIcon" />
                <span class="font-weight-bolder text-colorBlack">Add Card</span>
              </template>
              <b-row class="d-flex align-items-center justify-content-start">
                <b-col md="8" class="py-50">
                  <h3 class="font-weight-bolder text-colorBlack m-0">
                    Add Card
                  </h3>
                </b-col>
                <b-col md="4" class="py-50">
                  <div class="pb-1 pt-1 text-right">
                    <b-button
                      size="md"
                      type="submit"
                      variant="colorBlue"
                      @click="addCard"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="font-weight-bolder text-colorWhite"
                      />
                      <span class="font-weight-bolder text-colorWhite">
                        Add Card</span
                      >
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-card class="mx-2" style="border-radius: 20px">
                <b-table
                  stacked="md"
                  :fields="fieldsCard"
                  :items="Card"
                  thead-class="tableHeadColor"
                  small
                  rounded
                >
                  <template #head()="data">
                    <div class="py-50">
                      <span class="font-weight-bolder text-colorBlack">{{
                        data.label
                      }}</span>
                    </div>
                  </template>

                  <template #cell(CardType)="row">
                    <div class="py-1">
                      <b-link :to="{ name: 'InstructorAssignmentDetail' }">
                        <span
                          class="font-weight-bolder text-colorBlack m-0"
                          style="font-size: 0.8rem"
                        >
                          {{ row.item.CardType ? row.item.CardType : "" }}
                          <br />
                        </span>
                      </b-link>
                    </div>
                  </template>
                  <template #cell(BankName)="row">
                    <span
                      class="font-weight-bolder text-colorBlack m-0"
                      style="font-size: 0.8rem"
                    >
                      {{ row.item.BankName ? row.item.BankName : "" }}
                    </span>
                  </template>

                  <template #cell(Name)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.Name ? row.item.Name : "" }}
                      </span>
                    </div>
                  </template>
                  <template #cell(CardNumber)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.CardNumber ? row.item.CardNumber : "" }}
                      </span>
                    </div>
                  </template>
                  <template #cell(ValidThru)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.ValidThru ? row.item.ValidThru : "" }}
                      </span>
                    </div>
                  </template>

                  <template #cell(manage)="">
                    <img
                      src="@/assets/images/icons/instructor/assignment/Delete.svg"
                      img-fluid
                      class="mx-1 cursor-pointer"
                      width="30px"
                      height="30px"
                    />
                    <img
                      src="@/assets/images/icons/instructor/assignment/Edit.svg"
                      img-fluid
                      width="30px"
                      height="28px"
                      class="cursor-pointer"
                      @click="editCard"
                    />
                  </template>
                </b-table>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
    <AddCardModal
      @modalClosed="onModalClosed"
      :key="`create-${addCardModalCount}`"
    />
    <EditCardModal
      @modalClosed="onModalClosed"
      :key="`edit-${editCardModalCount}`"
    />
  </div>
</template>

<script>
import AddCardModal from "@/components/uniHead/payment/AddCardModal.vue";
import EditCardModal from "@/components/uniHead/payment/EditCardModal.vue";
export default {
  components: {
    AddCardModal,
    EditCardModal,
  },
  data() {
    return {
      accountNo: "",
      addCardModalCount: 0,
      editCardModalCount: 0,
      fieldsCard: [
        { key: "CardType", label: "Card Type" },
        { key: "BankName", label: "Bank Name" },
        { key: "Name", label: "Name" },
        { key: "CardNumber", label: "Card Number" },
        { key: "ValidThru", label: "Valid Thru" },
        { key: "manage", label: "" },
      ],
      Card: [
        {
          CardType: " Primary",
          BankName: " Bank ABC",
          Name: " Rara Avis",
          CardNumber: " •••• •••• •••• 1234",
          ValidThru: " 03/21",
        },
        {
          CardType: " Primary",
          BankName: " Bank ABC",
          Name: " Rara Avis",
          CardNumber: " •••• •••• •••• 1234",
          ValidThru: " 03/21",
        },
        {
          CardType: " Primary",
          BankName: " Bank ABC",
          Name: " Rara Avis",
          CardNumber: " •••• •••• •••• 1234",
          ValidThru: " 03/21",
        },
        {
          CardType: " Primary",
          BankName: " Bank ABC",
          Name: " Rara Avis",
          CardNumber: " •••• •••• •••• 1234",
          ValidThru: " 03/21",
        },
      ],
      max: 20,
    };
  },
  methods: {
    addCard() {
      this.addCardModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("add-card-modal");
      });
    },
    editCard() {
      this.editCardModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("edit-card-modal");
      });
    },
    async onModalClosed() {},
  },
};
</script>

<style></style>
