<template>
  <b-modal
    id="add-card-modal"
    centered
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    hide-footer
  >
    <template #modal-title>
      <h2 class="m-0 text-colorBlack">Add Card</h2>
    </template>
    <b-form>
      <b-form-row class="py-1">
        <b-col>
          <span class="font-weight-bolder text-colorBlack">Card Number</span>
          <b-form-group class="mb-0">
            <b-form-input
              id="CardNo"
              placeholder="XXXXXXXXXXXXXS"
              v-bind="CardNo"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row class="py-1">
        <b-col>
          <span class="font-weight-bolder text-colorBlack">MM/YY</span>
          <b-form-group class="mb-0">
            <b-form-input
              id="monthYear"
              placeholder="MM/YY"
              v-bind="monthYear"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row class="py-1">
        <b-col>
          <span class="font-weight-bolder text-colorBlack"
            >Card Holder Name</span
          >
          <b-form-group class="mb-0">
            <b-form-input
              id="cardHolderName"
              placeholder="Card Holder Name"
              v-bind="cardHolderName"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>

    <b-form-group>
      <b-button
        block
        type="submit"
        variant="colorBlue"
        style="margin-top: 400px"
        pill
      >
        Add
      </b-button>
    </b-form-group>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  props: {},
  mixins: [util],
  data() {
    return {
      cardNo: "",
      monthYear: "",
      cardHolderName: "",
    };
  },
  async mounted() {},
  methods: {
    ...mapActions({}),
  },
  computed: {},
};
</script>

<style scoped></style>
